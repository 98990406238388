import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from '../styles/utils'

import { Layout, PageHeader, Testimonial, News, GoogleMap } from '../components'
import { container, padding, boxShadow } from '../styles/global'

const Investment = props => {
    const { data } = props.pageContext

    const breadcrumbs = {
        heading: {
            title: 'Invest with Strive',
            link: '/investments/'
        },
        description: {
            title: 'Completed Investments',
            link: '/investments/'
        },
        title: data.location,
    }

    const renderMap = () => {
        return (
            <MapWrapper>
                <GoogleMap map={data.map} padding={true} />
            </MapWrapper>
        )
    }

    const renderIntro = () => {
        return (
            <Intro>
                <Grid>
                    <Info
                        dangerouslySetInnerHTML={{
                            __html: data.intro_text,
                        }}
                    />
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: data.intro_main_text,
                        }}
                    />
                </Grid>
            </Intro>
        )
    }

    const renderInvestmentData = () => {
        const investment_data_items = data.investment_data.map((item, i) => {
            return (
                <Item key={i}>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: item.title,
                        }}
                    />
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item.value,
                        }}
                    />
                </Item>
            )
        })

        return (
            <InvestmentData>
                <Grid>{investment_data_items}</Grid>
            </InvestmentData>
        )
    }

    const renderExtraData = () => {
        const extra_data_items = data.extra_data.map((item, i) => {
            return (
                <Item key={i}>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: item.title,
                        }}
                    />
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item.short_text,
                        }}
                    />
                </Item>
            )
        })

        return (
            <ExtraData>
                <Grid>{extra_data_items}</Grid>
            </ExtraData>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            {data && (
                <PageHeader
                    breadcrumbs={breadcrumbs}
                    page_heading={data.title ? data.title : undefined}
                    sub_heading={data.location ? data.location : undefined}
                    page={'investments-single'}
                />
            )}
            <Wrapper>
                <Container>
                    {renderMap()}
                    {renderIntro()}
                    {data.investment_data_display && renderInvestmentData()}
                    {data.extra_data_display && renderExtraData()}
                </Container>
            </Wrapper>

            {data.testimonials && (
                <Testimonial testimonials={data.testimonials} category={'investor'} bgColor={'dark'} />
            )}
            {data.news_posts && <News news_posts={data.news_posts} />}
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``

const Wrapper = styled.div`
    ${tw`pb-64 bg-white`};

    ${media.mob`
        ${tw`pb-32`};
    `}
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Grid = styled.div`
    ${tw`flex`};

    ${media.mob`
        ${tw`block`};
    `}
`

// Map

const MapWrapper = styled.div`
    ${tw`relative z-10 w-full -mt-8 bg-white`};
    height: 0;
    padding-bottom: 56.25%;
    ${boxShadow};
`

// Intro

const Intro = styled.div`
    ${tw`py-12`};

    ${Info}, ${Description} {
        ${tw`w-1/2`};

        ${media.mob`
            ${tw`w-full`};
        `}
    }

    ${Info} {
        ${tw`pr-16 text-orange`};
        font-weight: 600; /* semibold */
        font-size: 1.375rem;
        line-height: 1.19;

        ${media.tabPor`
            ${tw`pr-8`};
        `}

        ${media.mob`
            ${tw`pr-0`};
        `}
    }

    ${Description} {
        ${tw`pl-16 text-darkBlue`};
        font-weight: 300; /* light */
        font-size: 14px;
        line-height: 1.53;

        ${media.tabPor`
            ${tw`pl-8`};
        `}

        ${media.mob`
            ${tw`mt-8 pl-0`};
        `}
    }
`

// Investment Data

const InvestmentData = styled.div`
    ${tw`py-12 border-t border-b border-darkBlue`};

    ${Grid} {
        ${tw`flex-wrap -mx-4 -mb-6`};

        ${media.mob`
            ${tw`mx-0`};
        `}
    }

    ${Item} {
        ${tw`w-1/3 px-4 mb-6`};

        ${media.mob`
            ${tw`w-full px-0`};
        `}

        ${Heading} {
            ${tw`text-orange`};
            font-weight: 600; /* semibold */
            font-size: 1.375rem;
            line-height: 1.19;
        }

        ${Description} {
            ${tw`mt-1 text-darkBlue`};
            font-weight: 300; /* light */
            font-size: 1.125rem;
            line-height: 1.42;
        }
    }
`

// Extra Data

const ExtraData = styled.div`
    ${tw`py-12 border-t border-b border-darkBlue`};
    margin-top: -1px;

    ${media.mob`
        ${tw`border-b-0 pb-0`};
    `}

    ${Grid} {
        ${tw`flex-wrap -mx-8 -mb-6`};

        ${media.mob`
            ${tw`mx-0`};
        `}
    }

    ${Item} {
        ${tw`w-1/2 px-8 mb-6`};

        ${media.mob`
            ${tw`w-full px-0`};
        `}

        ${Heading} {
            ${tw`text-orange`};
            font-weight: 600; /* semibold */
            font-size: 1.375rem;
            line-height: 1.19;
        }

        ${Description} {
            ${tw`mt-1 text-darkBlue`};
            font-weight: 300; /* light */
            font-size: 14px;
            line-height: 1.53;
        }
    }
`

export default Investment
